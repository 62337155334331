import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  postCreateComment: (proj_id: string, formulation_id: string) =>
    `/org/project/${proj_id}/comments/formulation/${formulation_id}`,
  getAllProjectComments: (proj_id: string) =>
    `/org/project/${proj_id}/comments`,
  getAllFormulationComments: (proj_id: string, formulation_id: string) =>
    `/org/project/${proj_id}/comments/formulation/${formulation_id}`,
};

const postCreateComment = (data: {
  projectId: string;
  formulationId: string;
  comment: {
    text: string;
    userId: string;
  };
}) => {
  return BaseClient.post(
    ENDPOINTS.postCreateComment(data.projectId, data.formulationId),
    data.comment
  );
};

const getAllProjectComments = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getAllProjectComments(parameters.queryKey[1])
  );
};

const getAllFormulationComments = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getAllFormulationComments(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};

export const CommentRepository = {
  postCreateComment,
  getAllProjectComments,
  getAllFormulationComments,
};
