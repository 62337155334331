import React, { useEffect, useState } from 'react';

export const InitiativesLoader = ({
  isFinished,
  creationDate,
}: {
  isFinished: boolean;
  creationDate: string;
}) => {
  const [progress, setProgress] = useState<number>(0);

  const calculateInitialProgress = (): number => {
    const now = new Date();
    const creationTime = new Date(creationDate);
    const timeSpentInMinutes = (now.getTime() - creationTime.getTime()) / 60000;

    const initialProgress = Math.min((timeSpentInMinutes / 1) * 90, 90);
    return initialProgress;
  };

  useEffect(() => {
    setProgress(calculateInitialProgress());

    let interval: NodeJS.Timeout | undefined;
    if (progress < 90) {
      interval = setInterval(() => {
        setProgress(prev => {
          const nextValue = prev + 1;
          return nextValue >= 90 ? 90 : Number(nextValue.toFixed(0));
        });
      }, 100);
    } else if (progress >= 90 && !isFinished) {
      interval = setInterval(() => {
        setProgress(prev => (prev < 100 ? prev + 1 : prev));
      }, 1000);
    }

    if (isFinished && progress >= 90) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [progress, isFinished, creationDate]);

  return (
    <div>
      <p>Loading: {progress.toFixed(0)}%</p>
    </div>
  );
};

export default InitiativesLoader;
